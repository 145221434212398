import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Fitness App Template | Uizard"
    canonical="/templates/mobile-app-templates/fitness-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/fitness-tablet-app/"
    metaDescription="Ready to see some serious gains? Our new fitness app design template for tablet is here to transform the way you design. Sign up today."
    description="
    h2:Introducing BeFit, our fitness app UI design template for tablet
    <br/>
    Ready to flex your design muscles? Want to be the quickest <a:https://uizard.io/prototyping/>UI prototyper</a> on the track? Our new sports fitness UI template is here to transform you from average Joe to the elite product designer you were born to be. BeFit comes fully kitted out with all the must-have features for any self-respecting fitness app design. Simply sign up to Uizard Pro and streamline your design flow today.
    <br/>
    h3:A UI template for a stunning fitness tablet app
    <br/>
    Here at Uizard, we pride ourselves on crafting the best design solutions for product designers, regardless of skill or experience. BeFit boasts a stunning, sports aesthetic that is sure to tick your boxes on style and substance. Our <a:https://uizard.io/templates/>UI templates</a> are fully customizable though, meaning you have unlimited flexibility to add screens, change coloring, and even change the entire theme of the app design.
    <br/>
    h3:There is no 'I' in team…
    <br/>
    Working with a team? We have made collaboration easier than ever before with our smart sharing features, meaning you can share your project with stakeholders with the click of a button and collaborate in real time. If the team wins, we all do.
    "
    pages={[
      "A detailed onboarding screen for the user",
      "A home dashboard with various workouts",
      "A personal profile with a leaderboard and user's achievements",
      "A workout overview and workout-in-progress preview",
    ]}
    projectCode="xVoXmm0bYAIn9wlzm84K"
    img1={data.image1.childImageSharp}
    img1alt="fitness tablet app design template cover screen"
    img2={data.image2.childImageSharp}
    img2alt="fitness tablet app design template onboarding screen"
    img3={data.image3.childImageSharp}
    img3alt="fitness tablet app design template workout screen"
    img4={data.image4.childImageSharp}
    img4alt="fitness tablet app design template favorites screen"
    img5={data.image5.childImageSharp}
    img5alt="fitness tablet app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/fitness-tablet-app/fitness-tab-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/fitness-tablet-app/fitness-tab-app-onboard.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/fitness-tablet-app/fitness-tab-app-workout.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/fitness-tablet-app/fitness-tab-app-faves.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/fitness-tablet-app/fitness-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
